import { initializeApp } from "firebase/app";

function initializeFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyCfAZRJtccHH1ux1daQWWYL8-dFLSgY7Xo",
    authDomain: "ntust-arch-2021.firebaseapp.com",
    databaseURL:
      "https://ntust-arch-2021-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ntust-arch-2021",
    storageBucket: "ntust-arch-2021.appspot.com",
    messagingSenderId: "204023718080",
    appId: "1:204023718080:web:eca2c8e2d90a32044e4d25",
    measurementId: "G-L86X7FHG5C",
  };

  return initializeApp(firebaseConfig);
}

export default initializeFirebase;
