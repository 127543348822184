<template>
  <div class="works-list">
    <div :key="i" v-for="(item, i) in dataList" class="item">
      <div @click="openLightBox(i)" class="image-wrapper">
        <img :src="item.image" />
        <div class="mask" />
      </div>
    </div>
  </div>
  <WorkLightBox
    v-if="lightBoxData"
    :currentIndex="lightBoxDataIndex"
    :totalLength="dataList.length"
    :image="lightBoxData?.image"
    :onClose="closeLightBox"
    :toNextWork="toNextWork"
    :toPreviousWork="toPreviousWork"
  />
</template>

<script>
import WorkLightBox from "@/components/molecules/WorkLightBox";
import { computed, ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
export default {
  name: "WorksList",
  components: { WorkLightBox },
  props: {
    dataList: Array,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const lightBoxDataIndex = computed(() => {
      return Number(route.query.work);
    });

    const lightBoxData = computed(() => {
      return props.dataList[lightBoxDataIndex.value];
    });

    const openLightBox = (index) => {
      router.push(`${route.path}?work=${index}`);
    };

    const closeLightBox = () => {
      router.push(`${route.path}`);
    };

    const toNextWork = () => {
      let i = lightBoxDataIndex.value + 1;
      if (i === props.dataList.length) {
        i = 0;
      }

      router.push(`${route.path}?work=${i}`);
    };

    const toPreviousWork = () => {
      let i = lightBoxDataIndex.value - 1;
      if (i === -1) {
        i = props.dataList.length - 1;
      }

      router.push(`${route.path}?work=${i}`);
    };

    return {
      lightBoxDataIndex,
      lightBoxData,
      openLightBox,
      closeLightBox,
      toNextWork,
      toPreviousWork,
    };
  },
};
</script>

<style lang="scss" scoped>
.works-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 220px;
  width: 100%;

  @include RWD($mediumLaptop) {
    margin-top: 150px;
  }

  @include RWD($tablet) {
    margin: 80px -15px 0;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .item {
    flex: 0 0 calc(100% / 3);

    @include RWD($tablet) {
      margin-bottom: 40px;
    }

    .image-wrapper {
      width: 100%;
      padding-bottom: (100% * 9 / 16);
      position: relative;
      cursor: pointer;

      img {
        position: absolute;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      &:hover {
        .mask {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: $neon;
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
