<template>
  <div class="student-info-box">
    <section class="content-wrapper">
      <div
        ref="contentRef"
        class="content"
        :style="{ transform: `translateY(-${position}px)` }"
      >
        <span class="item">{{ content }}</span>
      </div>
    </section>
    <section class="indicators">
      <button @click="scrollUp" type="button" class="up">
        <img src="@/images/icons/uptriangle.svg" />
      </button>
      <button @click="scrollDown" type="button" class="down">
        <img src="@/images/icons/uptriangle.svg" />
      </button>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "ProjectInfoBox",
  props: {
    content: String,
  },
  setup() {
    const position = ref(0);
    const contentRef = ref(null);

    const scrollUp = () => {
      if (position.value === 0) return;

      position.value -= 50;
    };

    const scrollDown = () => {
      const maxScrollHeight = contentRef.value.clientHeight - 350;
      if (position.value > maxScrollHeight) return;

      position.value += 50;
    };

    return {
      position,
      contentRef,
      scrollUp,
      scrollDown,
    };
  },
};
</script>

<style lang="scss" scoped>
.student-info-box {
  display: flex;
  flex-direction: column;

  .content-wrapper {
    height: 350px;
    overflow: hidden;

    @include RWD($tablet) {
      height: auto;
      overflow: auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    span {
      text-align: justify;
      font-size: 20px;
      line-height: 50px;
      font-family: "Zpix";
      color: $black;
      white-space: pre-line;
      letter-spacing: 1px;

      @include RWD($mediumLaptop) {
        font-size: 14px;
        line-height: 36px;
      }

      @include RWD($tablet) {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 2px;
      }
    }
  }

  .indicators {
    margin-top: 20px;
    margin-left: auto;

    @include RWD($tablet) {
      display: none;
    }

    & > button {
      & + button {
        margin-left: 10px;
      }

      &.down {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
