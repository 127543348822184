<template>
  <div class="portfolio-page">
    <h2 class="page-title">STUDENT PORTFOLIO</h2>
    <section class="page-content">
      <StudentIntro
        :studentData="profileData.student"
        :categoryKey="profileData.categoryIndex"
        :categoryName="profileData.category"
        :categoryNameEn="profileData.categoryNameEn"
      />
      <ProjectIntro
        :profileData="profileData"
        :name="profileData.name"
        :nameEn="profileData.nameEn"
        :address="profileData.address"
        :software="profileData.software"
        :description="profileData.description"
        :youtubeUrl="profileData.youtubeUrl"
        :carouselImages="
          profileData.images.slice(0, profileData.youtubeUrl ? 2 : 3)
        "
      />
      <WorksList :dataList="profileData.images" />
      <div class="teammates">
        <p class="title">SEE OTHER TEAMMATES</p>
        <div class="list" v-if="media === 'desktop'">
          <StudentProfileCard
            :key="mate.nameInEnglish"
            v-for="mate in mates"
            namePosition="outside"
            :profileData="mate"
            :marginLeft="'auto'"
          />
        </div>
        <div v-else class="list mobile">
          <MobileProfileSwiper
            :profileList="mates"
            namePosition="inside"
            :shadowColor="'white'"
            :startFrom="'middle'"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import StudentProfileCard from "@/components/atoms/StudentProfileCard";
import MobileProfileSwiper from "@/components/molecules/MobileProfileSwiper";
import StudentIntro from "./StudentIntro";
import ProjectIntro from "./ProjectIntro";
import WorksList from "./WorksList";

export default {
  name: "EachPortfolio",
  components: {
    StudentIntro,
    ProjectIntro,
    WorksList,
    StudentProfileCard,
    MobileProfileSwiper,
  },
  setup () {
    const route = useRoute();
    const media = inject("media");
    const store = useStore();

    const profileData = computed(() => {
      return store.getters.portfolios.find((p) => p.work_id == route.params.id);
    });

    const mates = computed(() => {
      return store.getters.students.filter(
        (s) => s.advisor == profileData.value.student.advisor
      )
    })

    return {
      mates,
      media,
      profileData,
    };
  },
  // watch: {
  //   '$route.params.id' (id) {
  //     this.profileData = this.$store.getters.portfolios.find(
  //       (p) => p.work_id == id
  //     );
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.portfolio-page {
  @include page;

  .page-title {
    @include page-title($black);
  }

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    @include RWD($tablet) {
      padding: 0 15px;
    }
  }
}

.teammates {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;

  @include RWD($tablet) {
    margin-top: 70px;
    margin-left: -15px;
    margin-right: -15px;
  }

  & > .title {
    font-family: "Red Rose";
    font-size: 80px;
    font-weight: 400;
    line-height: 105px;
    text-align: center;
    margin-bottom: 100px;

    @include RWD($mediumLaptop) {
      font-size: 74px;
    }

    @include RWD($tablet) {
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 60px;
    }
  }

  .list {
    display: flex;
    align-items: flex-start;

    & > div {
      width: 300px;

      &:not(:last-child) {
        margin-right: 80px;
      }
    }
  }

  .list.mobile {
    width: 100%;

    & > div {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
