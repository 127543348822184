<template>
  <div class="project-intro">
    <div class="title">
      <span>PROJECT</span>
      <span>{{ name }}</span>
    </div>
    <section class="content">
      <div class="banner">
        <div class="media-wrapper">
          <div
            class="media-list"
            :style="{ transform: `translateX(${-100 * currentMediaIndex}vw)` }"
          >
            <div key="0" class="media-item" v-if="youtubeUrl">
              <YoutubeVideo
                :videoUrl="youtubeUrl"
                :is-current-slide="currentMediaIndex === 0"
              />
            </div>
            <div key="1" class="media-item" v-else>
              <img :src="getImage(0)" />
            </div>
            <div key="2" class="media-item">
              <img :src="getImage(this.youtubeUrl ? 0 : 1)" />
            </div>
            <div key="3" class="media-item">
              <img :src="getImage(this.youtubeUrl ? 1 : 2)" />
            </div>
          </div>
        </div>
        <div class="indicators">
          <div class="numbers">{{ currentMediaIndex + 1 }}/3</div>
          <div class="buttons">
            <button @click="toPrevious">
              <img src="@/images/icons/buttonLeft.svg" />
            </button>
            <button @click="toNext">
              <img src="@/images/icons/buttonRight.svg" />
            </button>
          </div>
        </div>
      </div>
      <div class="detail-intro">
        <div class="left">
          <div class="block">
            <p class="title">Project Full Title</p>
            <div class="content">
              <p class="name-m">
                {{ name }}
              </p>
              <p class="name-e">
                {{ nameEn }}
              </p>
            </div>
          </div>
          <div class="block">
            <p class="title">Project Site</p>
            <div class="content">
              <p class="address">{{ address }}</p>
            </div>
          </div>
          <div class="block">
            <p class="title">Software</p>
            <div class="content">
              <p class="software">
                {{ software }}
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="block intro">
            <p class="title">Project Description</p>
            <ProjectInfoBox :content="description" />
          </div>
          <img class="line" src="@/images/introBlockLine.png" />
          <button
            @click="open3dLightBox"
            class="cta-button"
            v-if="profileData.threeDWorkUrl"
          >
            <img src="@/images/icons/ctaArrow.svg" />
            <span>SEE 3D WORK</span>
          </button>
        </div>
      </div>
    </section>
  </div>
  <ThreeDModal
    v-if="isShow3dBox"
    @close="isShow3dBox = false"
    :data="profileData"
  />
</template>

<script>
import ProjectInfoBox from "@/components/atoms/ProjectInfoBox";
import YoutubeVideo from "@/components/molecules/YoutubeVideo";
import ThreeDModal from "@/components/organisms/ThreeDModal";
import { ref, computed } from "vue";
export default {
  name: "ProjectIntro",
  components: { ProjectInfoBox, YoutubeVideo, ThreeDModal },
  props: {
    name: String,
    nameEn: String,
    address: String,
    software: String,
    description: String,
    youtubeUrl: String,
    carouselImages: Array,
    profileData: Object,
  },
  setup(props) {
    const currentMediaIndex = ref(0);
    const isShow3dBox = ref(false);

    const open3dLightBox = () => {
      isShow3dBox.value = true;
    };

    const getImage = (index) => {
      if (props.carouselImages[index]) return props.carouselImages[index].image;
      return null;
    };

    const toNext = () => {
      if (currentMediaIndex.value === 2) {
        currentMediaIndex.value = 0;
        return;
      }
      currentMediaIndex.value += 1;
    };

    const toPrevious = () => {
      if (currentMediaIndex.value === 0) {
        currentMediaIndex.value = 2;
        return;
      }
      currentMediaIndex.value -= 1;
    };
    const images = computed(() => {
      let images = [{ image: null }, { image: null }, { image: null }];
      props.carouselImages.forEach((img, idx) => {
        images[idx] = img;
      });
      return images;
    });

    return {
      toNext,
      toPrevious,
      getImage,
      currentMediaIndex,
      images,
      isShow3dBox,
      open3dLightBox,
    };
  },
};
</script>

<style lang="scss" scoped>
$center-content-width: 1120px;
$center-content-width-medium: 800px;

.project-intro {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 260px;

  @include RWD($mediumLaptop) {
    margin-top: 150px;
  }

  @include RWD($tablet) {
    margin-top: 60px;
  }

  & > .title {
    display: flex;
    align-items: center;
    margin: 0 auto 60px;

    @include RWD($tablet) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    span {
      color: $black;

      &:first-child {
        font-family: "Red Rose";
        font-size: 80px;
        font-weight: 400;
        line-height: 105px;
        letter-spacing: 0.04em;
        margin-right: 70px;

        @include RWD($mediumLaptop) {
          font-size: 74px;
        }

        @include RWD($tablet) {
          font-size: 36px;
          line-height: 45px;
          margin-bottom: 20px;
        }
      }

      &:last-child {
        font-family: "Noto Sans TC";
        font-size: 35px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: 0.02em;

        @include RWD($mediumLaptop) {
          font-size: 30px;
        }

        @include RWD($tablet) {
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
  }
}

.banner {
  margin-bottom: 120px;

  @include RWD($tablet) {
    margin: 0 -15px 30px;
  }

  .media-wrapper {
    width: 100%;
    padding-bottom: (100% * 9)/16;
    position: relative;
    overflow: hidden;

    .media-list {
      display: flex;
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      transition: 0.3s all;

      .media-item {
        width: 100vw;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .indicators {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: $center-content-width;
    margin: 30px auto 0;

    @include RWD($mediumLaptop) {
      width: $center-content-width-medium;
    }

    @include RWD($tablet) {
      width: auto;
      padding-right: 15px;
      margin: 20px auto 0;
    }

    .numbers {
      font-family: "Zpix";
      font-size: 20px;
      line-height: 50px;
      margin-right: 45px;
      color: $black;

      @include RWD($tablet) {
        font-size: 14px;
        line-height: 14px;
        margin-right: 12px;
      }
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 50px;
      height: 50px;
      border: 1px solid $black;

      @include RWD($tablet) {
        width: 24px;
        height: 24px;
      }

      &:last-child {
        border-left: none;
      }

      img {
        width: 24px;

        @include RWD($tablet) {
          width: 12px;
        }
      }
    }
  }
}

.detail-intro {
  display: flex;
  justify-content: space-between;
  width: $center-content-width;
  margin: 0 auto;

  @include RWD($mediumLaptop) {
    width: $center-content-width-medium;
  }

  @include RWD($tablet) {
    display: block;
    width: auto;
  }

  .left {
    flex: 0 0 350px;

    @include RWD($mediumLaptop) {
      flex: 0 0 260px;
    }

    .block {
      &:not(:last-child) {
        margin-bottom: 50px;
      }

      @include RWD($tablet) {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }

  .right {
    flex: 1 1 auto;
    margin-left: 60px;

    @include RWD($tablet) {
      margin-left: 0;
    }

    .block {
      &.intro {
        @include RWD($tablet) {
          margin-right: 0;
        }
      }
    }

    .line {
      width: 100%;
      margin-top: 15px;

      @include RWD($tablet) {
        margin-top: 60px;
      }
    }

    .cta-button {
      display: flex;
      align-items: center;
      padding: 0;
      font-size: 0;
      position: relative;
      margin-top: 57px;

      @include RWD($tablet) {
        position: static;
        margin: 60px auto 0;
      }

      img {
        flex: 0 0 auto;
        width: 100px;
        margin-right: 40px;

        @include RWD($tablet) {
          width: 60px;
          margin-right: 28px;
        }
      }

      span {
        flex: 0 0 auto;
        font-family: "Red Rose";
        font-size: 70px;
        font-weight: 400;
        line-height: 100px;
        letter-spacing: 1px;
        white-space: nowrap;
        color: $black;

        @include RWD($mediumLaptop) {
          font-size: 46px;
        }

        @include RWD($tablet) {
          font-size: 30px;
          line-height: 37px;
        }
      }
    }
  }
}

.block {
  .title {
    font-family: "Red Rose";
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 15px;

    &::before {
      content: "";
      display: inline-block;
      width: 17px;
      height: 17px;
      top: 2px;
      background-color: $black;
      margin-right: 12px;
      position: relative;
    }

    @include RWD($mediumLaptop) {
      font-size: 14px;
      margin-bottom: 10px;

      &::before {
        width: 11px;
        height: 11px;
        top: 0;
      }
    }
  }

  .content {
    & > p {
      margin: 0;
      font-family: "Zpix";
      font-weight: 500;

      &.name-m {
        font-size: 25px;
        line-height: 45px;
        margin-bottom: 10px;

        @include RWD($mediumLaptop) {
          font-size: 18px;
          line-height: 38px;
        }

        @include RWD($tablet) {
          font-size: 18px;
          line-height: 30px;
        }
      }

      &.name-e {
        font-size: 20px;
        line-height: 35px;

        @include RWD($mediumLaptop) {
          font-size: 14px;
          line-height: 26px;
        }

        @include RWD($tablet) {
          font-size: 16px;
          line-height: 30px;
        }
      }

      &.address {
        font-size: 20px;
        line-height: 50px;

        @include RWD($mediumLaptop) {
          font-size: 18px;
          line-height: 38px;
        }

        @include RWD($tablet) {
          font-size: 16px;
          line-height: 30px;
        }
      }

      &.software {
        font-size: 20px;
        line-height: 50px;
        white-space: pre-wrap;

        @include RWD($mediumLaptop) {
          font-size: 14px;
          line-height: 36px;
        }

        @include RWD($tablet) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
