<template>
  <div class="student-info-box">
    <section class="content-wrapper">
      <div
        ref="contentRef"
        class="content"
        :style="{ transform: `translateY(-${position}px)` }"
      >
        <span class="item">學生姓名：{{ name }}</span>
        <span class="item">指導老師：{{ mentor }}</span>
        <span class="item">基地地址：{{ address }}</span>
        <span class="item">設計關鍵字：{{ keywords }}</span>
        <span class="item">格言：{{ motto }}</span>
        <span class="item">你的成分：{{ ingredient }}</span>
        <span class="item"> 一行表達你對畢業設計的想法：{{ thought }}</span>
        <span class="item">個人網站連結：{{ website }}</span>
      </div>
    </section>
    <section class="indicators">
      <button @click="scrollUp" type="button" class="up">
        <img src="@/images/icons/uptriangle.svg" />
      </button>
      <button @click="scrollDown" type="button" class="down">
        <img src="@/images/icons/uptriangle.svg" />
      </button>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "StudentInfoBox",
  props: {
    name: String,
    mentor: String,
    address: String,
    keywords: String,
    motto: String,
    ingredient: String,
    thought: String,
    website: String,
  },
  setup(props) {
    const position = ref(0);
    const contentRef = ref(null);

    const scrollUp = () => {
      if (position.value < 0) return;

      position.value -= 50;
    };

    const scrollDown = () => {
      const maxScrollHeight = contentRef.value.clientHeight - 250;
      if (position.value > maxScrollHeight) return;

      position.value += 50;
    };

    return {
      position,
      contentRef,
      scrollUp,
      scrollDown,
    };
  },
};
</script>

<style lang="scss" scoped>
.student-info-box {
  display: flex;
  flex-direction: column;

  .content-wrapper {
    height: 250px;
    overflow: hidden;

    @include RWD($tablet) {
      height: auto;
      overflow: auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    span {
      font-size: 20px;
      line-height: 50px;
      font-family: "Zpix";
      letter-spacing: 2%;
      color: $black;

      @include RWD($mediumLaptop) {
        font-size: 14px;
        line-height: 36px;
      }

      @include RWD($tablet) {
        font-size: 16px;
        line-height: 40px;
      }
    }
  }

  .indicators {
    margin-top: 10px;
    margin-left: auto;

    & > button {
      & + button {
        margin-left: 10px;
      }

      &.down {
        img {
          transform: rotate(180deg);
        }
      }
    }

    @include RWD($tablet) {
      display: none;
    }
  }
}
</style>
