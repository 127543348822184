<template>
  <teleport to="#portal-target">
    <div class="works-lightbox">
      <button @click="onClose" class="close-button">
        <img src="@/images/icons/close-icon.svg" />
      </button>
      <div class="content">
        <button v-if="media === 'desktop'" @click="toPreviousWork">
          <img src="@/images/icons/arrowLeft.svg" />
        </button>
        <div class="main-content">
          <section v-if="media === 'desktop'" class="numbers desktop">
            <span class="number"
              >{{ props.currentIndex + 1 }}/{{
                totalLength > 9 ? totalLength : `0${totalLength}`
              }}</span
            >
          </section>
          <div class="image-wrapper">
            <img :src="props.image" alt="work" />
          </div>
        </div>
        <button v-if="media === 'desktop'" @click="toNextWork">
          <img src="@/images/icons/arrowRight.svg" />
        </button>
        <div v-if="media === 'mobile'" class="info">
          <button @click="toPreviousWork">
            <img src="@/images/icons/arrowLeft.svg" />
          </button>
          <section class="numbers">
            <span class="number"
              >{{ props.currentIndex + 1 }}/{{
                totalLength > 9 ? totalLength : `0${totalLength}`
              }}</span
            >
          </section>
          <button @click="toNextWork">
            <img src="@/images/icons/arrowRight.svg" />
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { inject, onMounted, onUnmounted } from "@vue/runtime-core";
export default {
  name: "WorksLightBox",
  components: {},
  props: {
    currentIndex: Number,
    totalLength: Number,
    image: String,
    onClose: Function,
    toNextWork: Function,
    toPreviousWork: Function,
  },
  setup(props) {
    const { totalLength = 10, onClose, toNextWork, toPreviousWork } = props;
    const media = inject("media");

    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      document.body.style.overflow = "auto";
    });

    return {
      props,
      totalLength,
      onClose,
      toNextWork,
      toPreviousWork,
      media,
    };
  },
};
</script>

<style lang="scss" scoped>
.works-lightbox {
  @include modal;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.95);
  justify-content: flex-start;

  .close-button {
    width: 70px;
    margin: 30px 30px 20px auto;
    filter: invert(100%);

    img {
      width: 100%;
    }

    @include RWD($tablet) {
      width: 40px;
      margin: 10px 10px 10px auto;
      opacity: 0.3;
    }

    @include RWD($phone) {
      width: 50px;
      margin: 20px 20px 20px auto;
    }
  }
}

.numbers.desktop {
  display: flex;
  align-items: center;
  position: absolute;
  top: -40px;
  left: 0;

  & > .number {
    color: $black;
    font-family: "Zpix";
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 1px;
  }
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 0 30px;
  margin: auto 0;

  @include RWD($tablet) {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
	margin: 0;
    padding: 0;
  }

  .main-content {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    position: relative;
    margin: 0 100px;
    width: 100%;
    max-width: 1400px;

    @include RWD($tablet) {
      margin: 10px;
      height: 100%;
      max-height: 100%;
    }

    @include RWD($phone) {
      margin: 0;
      margin-bottom: 20px;
    }

    .image-wrapper {
      width: 100%;
      padding-bottom: 100% * 9 / 16;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @include RWD($tablet) {
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
  }

  .info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 20px;
    padding: 0 10px;
    opacity: 0.3;

    & > .numbers {
      .number {
        color: $black;
        font-family: "Zpix";
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }
  }

  button {
    width: 30px;

    @include RWD($tablet) {
      width: 12px;
    }

    img {
      width: 100%;
      filter: invert(100%);
    }
  }
}
</style>
