<template>
  <div class="student-intro">
    <div class="left">
      <div class="cover">
        <p class="area-title">Student Image</p>
        <div class="image barrage-wrapper">
          <div
            v-for="{ message, id } in messages"
            :key="id"
            class="barrage"
            :style="{
              animationDelay: `${Math.random() * messages.length}s`,
              top: `${Math.random() * 100}%`,
            }"
          >
            {{ message }}
          </div>

          <img :src="studentData.coverImage" />
        </div>
      </div>
    </div>
    <div class="right">
      <div class="detail">
        <div class="title-area-mobile">
          <p class="area-title">Details</p>
          <div v-if="media === 'mobile'" class="category">
            <span>CATEGORY</span>
            <div class="icon-area">
              <img :src="categoriesIcon.normal" />
            </div>
          </div>
        </div>
        <img src="@/images/divideLine.svg" />
        <div class="area-content">
          <StudentInfoBox
            :key="studentData.name"
            :studentData="studentData"
            :name="studentData.name"
            :address="studentData.address"
            :mentor="studentData.advisor.name"
            :keywords="studentData.keywords"
            :motto="studentData.motto"
            :ingredient="studentData.ingredient"
            :thought="studentData.thought"
            :website="studentData.portfolio"
          />
          <div v-if="media === 'desktop'" class="category">
            <span>CATEGORY</span>
            <div class="icon-area desktop">
              <img :src="categoriesIcon.normal" class="bounce-effect" />
              <img :src="categoriesIcon.hover" class="bounce-effect" />

              <div class="icon-tooltip">
                <div class="triangle" />
                <div class="content">
                  <span>{{ categoryName }}</span>
                  <span>{{ categoryNameEn }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message">
        <p class="area-title">Message</p>
        <div class="leave-message">
          <img src="@/images/messagebg.png" />
          <form @submit="onSubmitForm" class="form-wrapper">
            <div class="input-wrapper">
              <input
                maxLength="25"
                v-model="messageInput"
                placeholder="TALK TO ME..."
              />
              <span>{{ messageInput.length }}/25</span>
            </div>
            <button type="submit">ENTER</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import StudentInfoBox from "@/components/atoms/StudentInfoBox";

import cate01 from "@/images/categories-icon/01.png";
import cate02 from "@/images/categories-icon/02.png";
import cate03 from "@/images/categories-icon/03.png";
import cate04 from "@/images/categories-icon/04.png";
import cate05 from "@/images/categories-icon/05.png";
import cate06 from "@/images/categories-icon/06.png";
import cate07 from "@/images/categories-icon/07.png";
import cate08 from "@/images/categories-icon/08.png";
import cate09 from "@/images/categories-icon/09.png";
import cate10 from "@/images/categories-icon/10.png";
import cate11 from "@/images/categories-icon/11.png";
import cate12 from "@/images/categories-icon/12.png";
import cate13 from "@/images/categories-icon/13.png";
import cate14 from "@/images/categories-icon/14.png";
import cate15 from "@/images/categories-icon/15.png";

import hoverCate01 from "@/images/categories-icon/hover/01.png";
import hoverCate02 from "@/images/categories-icon/hover/02.png";
import hoverCate03 from "@/images/categories-icon/hover/03.png";
import hoverCate04 from "@/images/categories-icon/hover/04.png";
import hoverCate05 from "@/images/categories-icon/hover/05.png";
import hoverCate06 from "@/images/categories-icon/hover/06.png";
import hoverCate07 from "@/images/categories-icon/hover/07.png";
import hoverCate08 from "@/images/categories-icon/hover/08.png";
import hoverCate09 from "@/images/categories-icon/hover/09.png";
import hoverCate10 from "@/images/categories-icon/hover/10.png";
import hoverCate11 from "@/images/categories-icon/hover/11.png";
import hoverCate12 from "@/images/categories-icon/hover/12.png";
import hoverCate13 from "@/images/categories-icon/hover/13.png";
import hoverCate14 from "@/images/categories-icon/hover/14.png";
import hoverCate15 from "@/images/categories-icon/hover/15.png";

import initializeFirebase from "@/utils/firebase";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  limit,
} from "firebase/firestore";

export default {
  name: "StudentIntro",
  components: { StudentInfoBox },
  props: {
    studentData: Object,
    categoryKey: Number,
    categoryName: String,
    categoryNameEn: String,
  },
  setup(props) {
    const media = inject("media");

    const route = useRoute();

    const categoriesIcon = computed(() => {
      const categoriesIcon = {
        1: {
          normal: cate01,
          hover: hoverCate01,
        },
        2: {
          normal: cate02,
          hover: hoverCate02,
        },
        3: {
          normal: cate03,
          hover: hoverCate03,
        },
        4: {
          normal: cate04,
          hover: hoverCate04,
        },
        5: {
          normal: cate05,
          hover: hoverCate05,
        },
        6: {
          normal: cate06,
          hover: hoverCate06,
        },
        7: {
          normal: cate07,
          hover: hoverCate07,
        },
        8: {
          normal: cate08,
          hover: hoverCate08,
        },
        9: {
          normal: cate09,
          hover: hoverCate09,
        },
        10: {
          normal: cate10,
          hover: hoverCate10,
        },
        11: {
          normal: cate11,
          hover: hoverCate11,
        },
        12: {
          normal: cate12,
          hover: hoverCate12,
        },
        13: {
          normal: cate13,
          hover: hoverCate13,
        },
        14: {
          normal: cate14,
          hover: hoverCate14,
        },
        15: {
          normal: cate15,
          hover: hoverCate15,
        },
      };
      const key = props.categoryKey;
      return categoriesIcon[key];
    });
    const messageInput = ref("");

    const messages = reactive([]);

    const app = initializeFirebase();
    const db = getFirestore(app);

    onMounted(async () => {
      await getMessages();
    });

    async function getMessages() {
      try {
        const q = query(collection(db, route.params.id), limit(50));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          messages.push({
            message: doc.data().message,
          });
        });
      } catch (e) {
        console.log(e);
      }
    }

    const onSubmitForm = async (e) => {
      e.preventDefault();

      if (messageInput.value.length === 0) return;

      await addDoc(collection(db, route.params.id), {
        message: messageInput.value,
      });
      messages.push({
        message: messageInput.value,
      });
      messageInput.value = "";
    };

    return {
      media,
      categoriesIcon,
      messageInput,
      onSubmitForm,
      messages,
    };
  },
};
</script>

<style lang="scss" scoped>
.student-intro {
  display: flex;
  align-self: center;
  width: 1200px;
  justify-content: space-between;

  @include RWD($mediumLaptop) {
    max-width: 1000px;
  }

  @include RWD($tablet) {
    display: block;
    margin-top: 0;
    width: auto;
    max-width: 100vw;
  }

  .left {
    flex: 0 1 656px;
    margin-right: 50px;

    .cover {
      img {
        width: 100%;
      }
    }

    @include RWD($tablet) {
      margin-right: 0;
      margin-bottom: 58px;

      .cover {
        .image {
          margin: 0 -15px;
        }
      }
    }
  }

  .right {
    flex: 0 1 525px;
  }
}

.detail {
  font-size: 0;
  margin-bottom: 30px;

  @include RWD($tablet) {
    margin-bottom: 40px;
  }

  & > img {
    width: 100%;
    margin-bottom: 15px;
  }

  .area-content {
    display: flex;
    justify-content: space-between;

    @include RWD($tablet) {
      position: relative;
    }

    & > div {
      &:first-child {
        flex: 1 1 300px;
      }
    }
  }
}

.category {
  margin: 0 20px 0 45px;

  @include RWD($tablet) {
    margin: 0;
  }

  & > span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-family: "Zpix";
    font-size: 15px;

    @include RWD($mediumLaptop) {
      font-size: 12px;
    }

    @include RWD($tablet) {
      transform: scale(0.65);
      transform-origin: center;
    }
  }
}

.title-area-mobile {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;

  & > .area-title {
    margin-bottom: 0;
  }
}

.icon-area {
  width: 76px;
  height: 76px;
  padding: 12px;
  border: 2px solid $black;
  border-radius: 10px;
  margin-top: 15px;
  position: relative;

  @include RWD($mediumLaptop) {
    width: 50px;
    height: 50px;
    padding: 6px;
    margin-top: 5px;
  }

  @include RWD($tablet) {
    width: 54px;
    height: 54px;
    padding: 8px;
    margin: 0 auto;
  }

  & > img {
    width: 100%;

    &:first-child {
      display: inline;
    }
    &:nth-child(2) {
      display: none;
    }
  }

  & > .icon-tooltip {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    font-size: 0;

    display: none;
    align-items: center;

    & > .triangle {
      flex: 0 0 auto;
      width: 7px;
      height: 14px;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      background-color: $neon;
      margin: 0 5px 0 7px;
    }

    & > .content {
      display: flex;
      width: 300px;
      flex-direction: column;
      background-color: $neon;
      padding: 5px 8px;

      @include RWD($mediumLaptop) {
        width: 190px;
      }

      @include RWD($smallLaptop) {
        width: 150px;
      }

      & > span {
        font-family: "Zpix";
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 1px;

        @include RWD($mediumLaptop) {
          font-size: 13px;
        }
      }
    }
  }

  &.desktop {
    &:hover {
      border: 2px solid $neon;

      & > img {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          display: inline;
        }
      }

      & > .icon-tooltip {
        display: flex;
      }
    }
  }
}

.area-title {
  font-size: 20px;
  font-family: "Red Rose";
  letter-spacing: 1px;
  margin-bottom: 20px;

  &::before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    background-color: $black;
    margin-right: 12px;
    position: relative;
    top: 2px;
  }

  @include RWD($mediumLaptop) {
    font-size: 14px;
    line-height: 20px;

    &::before {
      width: 12px;
      height: 12px;
    }
  }

  @include RWD($tablet) {
    font-size: 16px;

    &::before {
      width: 8px;
      height: 8px;
      top: -1px;
    }
  }
}

.leave-message {
  position: relative;

  img {
    width: 100%;
  }

  .form-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    @include RWD($tablet) {
      padding: 10px 10px 20px;
    }

    .input-wrapper {
      flex: 1 1 350px;
      position: relative;
      margin-right: 40px;

      @include RWD($tablet) {
        margin-right: 30px;
      }

      input {
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid $black;

        font-size: 20px;
        line-height: 35px;
        font-family: "Zpix";
        color: $black;
        padding: 0 55px 0 3px;

        &::placeholder {
          font-size: 20px;
          line-height: 35px;
          font-family: "Zpix";
          color: $black;
        }

        @include RWD($mediumLaptop) {
          font-size: 14px;

          &::placeholder {
            font-size: 14px;
          }
        }

        @include RWD($tablet) {
          font-size: 16px;
          line-height: 24px;

          &::placeholder {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      span {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-family: "Zpix";
        color: rgba(0, 0, 0, 0.5);
      }
    }

    button {
      font-size: 20px;
      font-family: "Zpix";
      color: $black;

      @include RWD($mediumLaptop) {
        font-size: 14px;
        line-height: 50px;
      }

      @include RWD($tablet) {
        font-size: 16px;
        line-height: 16px;
        padding: 0 10px 2px;
      }
    }
  }
}

.bounce-effect {
  animation-name: zoomInDown;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes zoomInDown {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -600px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -600px, 0);
  }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}

.barrage-wrapper {
  position: relative;
  overflow: hidden;

  .barrage {
    position: absolute;
    width: 100%;
    right: 0;
    color: #ffffff;
    animation-name: barrage;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    transform: translateX(100%);
  }
}

@keyframes barrage {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-200%);
  }
}
</style>
